.messageContext {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 16px;
}

@media (max-width: 600px) and (min-width: 485px) {
  .messageContext {
    & > * {
      display: none;
      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
        display: block;
      }
    }
  }
}

@media (max-width: 484px) and (min-width: 370px) {
  .messageContext {
    & > * {
      display: none;
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        display: block;
      }
    }
  }
}

@media (max-width: 371px) {
  .messageContext {
    & > * {
      display: none;
      &:nth-child(1), &:nth-child(2) {
        display: block;
      }
    }
  }
}
